@import '../../../../../styles/main.scss';
.brand-ford {
    .ute-landing-breadcrumb {
        .breadcrumbs {
            padding-left: 0px !important;
        }
    }
    .secondary-button .ute-faq-cta {
        padding: 0px;
    }
    .ute-content {
        margin-bottom: 60px;
    }
    .ute-wrapper-section {
        .ute-heading-section {
            .ute-header {
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 24px;
                text-transform: uppercase;
                @include tablet {
                    font-size: 40px;
                    line-height: 48px;
                    text-align: left;
                }
            }
        }
        .ute-FormSucess {
            box-sizing: border-box;
            height: 48px;
            border: 1px solid $white;
            border-radius: 3px;
            background-color: $fds-color-primary;
            margin: 25px 0px;
            display: flex;
            align-items: center;
            padding-left: 16px;
            box-shadow: 0 1rem 1rem 0 rgb(0 0 0 / 10%),
                0 2rem 2rem 0 rgb(0 0 0 / 10%);
            transition: transform 0.3s ease-in-out,
                background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
                border-color 0.3s ease-in-out;
            p {
                color: $white;
                font-size: 11px;
                line-height: 16px;
                margin-bottom: 0px;
            }
        }
        p {
            color: $fds-text;
            font-family: $f1-font-regular;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 12px;
        }
        .ute-desc {
            p {
                margin-bottom: 24px;
            }
        }
        .uteTrailerProfiles {
            margin-bottom: 24px;
            ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                gap: 15px;
                @include tablet {
                    gap: 25px;
                }
                .add-trailerProfiles {
                    button {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
                li {
                    width: 100%;
                    @include tablet {
                        width: 47%;
                    }
                    @include md-desktop {
                        width: 30%;
                    }
                    button {
                        box-shadow: none;
                        border-radius: 0px;
                        color: $fds-color-primary;
                        font-family: $f1-font-regular;
                        font-size: 18px;
                        line-height: 24px;
                        text-align: left;
                        width: 100%;
                        padding: 0px 16px;
                        border: 0px;
                        background-color: $fds-color-white;
                        height: 40px;

                        &:disabled {
                            background-color: #bbbbbb;
                        }

                        @include tablet {
                            height: 60px;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
