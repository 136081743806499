.brand-lincoln {
    .vehicle-checklist-carousel-wrapper {
        margin-left: 16px;
        @include fds-large {
            margin-left: initial;
        }
        @include fds-x-small {
            margin-top: 30px;
        }
        .vehicle-checklist-title {
            color: $brand-primary;
            font-family: $proxima-nova-regular;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            border-bottom: 0.5px solid $brand-primary;
            margin-bottom: 20px;
        }
        .no-carousel {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            @include desktop {
                justify-content: center;
            }
            @include tablet {
                justify-content: center;
            }
        }
    }
}
