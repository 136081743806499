.brand-ford {
    .vhr-schedule-report-cta {
        display: flex;
        justify-content: center;
        @include tablet {
            justify-content: right;
        }
    }
    .schedule-report-modal {
        @include mobile {
            padding: 0px;
            margin-left: -2px;
            height: 100%;
            padding-bottom: 0px;
        }
        .modal-content {
            @media only screen and (max-height: 700px) {
                max-height: 700px;
                overflow-y: auto;
                height: auto;
            }
            @include mobile {
                padding-top: 0px;
                padding-bottom: 0px;
                margin: 0 !important;
                top: 0 !important;
                height: 100%;
            }
            @include tablet {
                max-width: 570px;
            }
            @include desktop {
                padding-left: 30px;
                padding-right: 30px;
            }
            p {
                padding-bottom: 30px;
            }
            .schedule-report-divider {
                box-shadow: none;
            }
            .modal-header {
                .modal-close {
                    @include mobile {
                        margin-right: 5px;
                    }
                    @include desktop {
                        margin-right: -30px;
                    }
                    img {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
            .modal-body {
                text-align: left;
                @include mobile {
                    padding: 0 30px;
                }
                .modal-heading {
                    font-family: $f1-font-regular;
                    font-size: 24px;
                    text-align: center;
                    margin-top: -15px;
                    padding-bottom: 38px;
                    line-height: 34px;
                    padding-right: 0px;
                    @include mobile {
                        padding-bottom: 24px;
                    }
                }
                .modal-subheading {
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    padding-bottom: 35px;
                    @include mobile {
                        padding: 0px 10px 30px 10px;
                    }
                }
                .schedule-report-message {
                    display: flex;
                    span {
                        content: url('../../../../assets/email.svg');
                        width: pToR(32);
                        height: pToR(25);
                        margin-right: pToR(15);
                    }
                }
                .schedule-report-offers {
                    display: flex;
                    span {
                        content: url('../../../../assets/offers-rebates.svg');
                        width: pToR(32);
                        height: pToR(28);
                        margin-right: pToR(15);
                    }
                }
                .schedule-report-divider {
                    box-shadow: none;
                }
                .schedule-report-email-wrapper {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    .schedule-report-email-label {
                        padding-bottom: 10px;
                        display: inline-block;
                        @include desktop {
                            display: inline;
                            position: relative;
                        }
                        span {
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                        }
                        .email-info-icon {
                            content: url('../../../../assets/info-icon.svg');
                            width: pToR(18);
                            height: pToR(18);
                            margin-bottom: -2px;
                            margin-left: 10px;
                            cursor: pointer;
                            @include mobile {
                                margin-top: pToR(13);
                                margin-left: pToR(10);
                                justify-content: center;
                            }
                        }
                    }
                    .schedule-report-email {
                        font-family: $f1-font-regular;
                        color: $fds-color-md-gray;
                        padding: 0px;
                        @include desktop {
                            display: inline;
                            float: right;
                        }
                    }
                    .schedule-report-email-label-text {
                        display: none;
                        box-shadow: 0 -1px 15px 0 rgba(0, 0, 0, 0.5);
                        border-radius: 3px;
                        width: 300px;
                        background-color: $white;
                        color: black;
                        text-align: left;
                        border: 1px solid $border-grey;
                        position: absolute;
                        z-index: 1;
                        margin-top: -135px;
                        left: 125px;
                        transition: opacity 0.3s;
                        @include mobile {
                            position: fixed;
                            width: 100%;
                            height: 100%;
                            z-index: 3;
                            margin: 0px;
                            top: 0;
                            left: 0;
                            border: 0px solid;
                            padding: 0;
                        }
                        .popover-close {
                            content: url('../../../../assets/close.svg');
                            width: 16px;
                            height: 16px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            cursor: pointer;
                            z-index: 3;
                            margin-top: 10px;
                            margin-right: 10px;
                            @include mobile {
                                position: fixed;
                                top: 10px;
                                margin-top: 0;
                                margin-right: 10px;
                                width: 20px;
                                height: 20px;
                                right: 10px;
                            }
                        }
                        .popover-heading {
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            line-height: 26px;
                            padding-right: 50px;
                            margin-bottom: 16px;
                            @include desktop {
                                padding-top: 15px;
                                padding-left: 15px;
                            }
                            @include mobile {
                                padding-top: 10px;
                                padding-left: 25px;
                            }
                        }

                        div {
                            @include desktop {
                                height: 200px;
                                padding-left: 15px;
                                overflow-y: auto;
                            }
                            @include mobile {
                                padding-left: 25px;
                                padding-right: 30px;
                                text-align: left;
                            }
                        }
                    }
                    .schedule-report-email-label-text::after {
                        content: '';
                        position: absolute;
                        @include desktop {
                            margin-left: -10px;
                            margin-top: -147px;
                            transform: rotate(-180deg);
                            border-top: 10px solid transparent;
                            border-left: 10px solid $fds-color-white;
                            border-bottom: 10px solid transparent;
                            border-right: none;
                        }
                    }
                }
                .schedule-report-toggle {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: $fds-color-dk-gray;
                    div.slider {
                        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
                    }
                }

                .dropdown-container-wrapper {
                    padding-top: 20px;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    label {
                        color: $fds-color-dk-gray;
                        font-size: 16px;
                        line-height: 26px;
                        position: relative;
                        @include mobile {
                            margin-right: 30px;
                        }
                    }
                    margin-bottom: 1rem;
                }
                .vhr-save-messages-container {
                    display: flex;
                    padding-top: 5px;
                    padding-bottom: 10px;
                    .vhr-save-message-icon {
                        margin-top: -1px;
                        width: 30px;
                        height: 30px;
                        padding-right: 10px;
                    }
                    span {
                        color: $fds-color-dk-gray;
                        &.error-text {
                            color: $error-message-text;
                        }
                    }
                    &.error-hr-line {
                        border-bottom: 8px solid $fds-color-red;
                    }
                }
            }
            .modal-footer {
                margin: 0.5rem 0 2rem 0;
                .primary-button.disabled {
                    &::before {
                        background-color: $fds-color-dk-gray;
                    }
                    &:hover {
                        &::before {
                            background-color: $fds-color-dk-gray !important;
                        }
                    }
                }
            }
        }
    }
}
