@import '../../../../../../styles/main';

.brand-ford {
    .ute-final-form {
        margin-bottom: 50px;

        .ute-header {
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 0px;
            text-transform: uppercase;
            @include tablet {
                font-size: 40px;
                line-height: 48px;
                text-align: left;
            }

            span {
                font-size: 12px;
                line-height: 24px;
                @include tablet {
                    font-size: 28px;
                    line-height: 48px;
                }
            }
        }
        .ute-complete-button {
            margin-left: 25px;
        }

        .dropdown-input-wrapper {
            @include desktop {
                display: grid;
                grid-template-columns: auto auto auto auto;
                gap: 0px 25px;
            }
            .ute-dropdown,
            .ute-input-container {
                width: 100%;
                @include desktop {
                    width: 250px;
                }
                @include fds-large {
                    width: 290px;
                }
                .error-message {
                    color: $fds-color-red;
                }
                .fds-style {
                    margin-top: 10px;
                }
            }
            .ute-dropdown-1 {
                @include desktop {
                    grid-column: 1 / 1;
                }
            }
            .ute-input-container-1 {
                @include desktop {
                    grid-column: 2 / 1;
                }
            }
            .trailer-form-textField {
                top: 30px;
                width: 100%;
                border-radius: 3px;
            }
            .input-desc {
                margin-top: 25px;
                color: $fds-text;
                font-family: $f1-font-regular;
                font-size: 12px;
                line-height: 16px;
            }
            .dropdown-container-wrapper {
                display: block;
                width: auto;
                margin-bottom: 10px;
                .dropdown {
                    min-width: auto;
                }
                label {
                    font-size: 12px;
                    color: $fds-color-dk-gray;
                }
            }
        }
        .ute-trailer-footer {
            .ute-cta-button-reverse {
                display: flex;
                flex-direction: column-reverse;
                @include desktop {
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                }
                &_wrap {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    @include desktop {
                        margin-bottom: 0px;
                        justify-content: initial;
                    }
                }
                .ute-cancel-button {
                    font-size: 18px;
                    line-height: 24px;
                    color: $fds-color-primary;
                    text-decoration: underline;
                    margin-right: 28px;
                    &::before {
                        box-shadow: none;
                        border: 1px solid $breadcrumb-blue;
                    }
                    &:not(.disabled) {
                        &:hover {
                            &::before {
                                border: 1px solid $breadcrumb-blue;
                            }
                        }
                    }
                }
            }
        }
        .apiErrorMsg {
            margin: 25px 0px;
            display: block;
            color: $fds-color-red;
        }
    }
}
