.brand-lincoln {
    .modal.terms-and-conditions {
        font-family: $proxima-nova-semi-bold;
        margin: 0;
        padding: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        overflow: hidden;

        @include tablet {
            padding: 100px;
            @media (max-height: 850px) {
                padding: 50px;
            }
        }
        .modal-content {
            margin: 0;
            padding: 0;
            background: #f4f4f4;
            position: absolute;
            top: 20px;
            bottom: 20px;
            left: 20px;
            right: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            border: none;
            border-radius: 3px;
            overflow: hidden;

            @include tablet {
                display: block;
                margin: auto auto 6rem;
                position: static;
                width: 100%;
                max-width: 1000px;
            }

            .modal-header {
                margin: 0;
            }
            .modal-body {
                margin: 0 auto;
                padding: 20px;
                background: $brand-white;
                word-break: break-word;
                flex: 1;
                overflow: scroll;
                color: $brand-black;
                box-sizing: border-box;
                width: 100%;

                @include tablet {
                    height: 500px;
                    padding: 80px 100px 0;
                    @media (max-height: 850px) {
                        height: 400px;
                    }
                }

                .tac-header {
                    font-family: $proxima-nova-regular;
                    margin-bottom: 1rem;
                    font-size: 2.125rem;
                    letter-spacing: 4px;
                    text-transform: uppercase;
                }
                p {
                    margin: 0.5rem 0;

                    strong {
                        font-family: $proxima-nova-regular;
                    }
                }
                img {
                    max-width: 100%;
                }
                h5 {
                    margin-bottom: 0.75rem;
                    font-weight: 100;
                    font-size: 1.5rem;
                    line-height: 32px;
                    a {
                        font-size: 1rem;
                        line-height: 24px;
                    }
                }
            }
            .modal-footer {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                @include tablet {
                    flex-direction: column;
                    justify-content: center;
                }

                button.primary-button {
                    margin-bottom: 30px;

                    @include tablet {
                        margin-bottom: 0;
                    }
                }

                .text-button {
                    margin-left: 0px;
                    color: $brand-secondary;
                    border-color: $brand-secondary;
                    &:hover {
                        border-color: $brand-border;
                    }
                    &::before {
                        background-color: transparent;
                        border: 1px solid $brand-secondary;
                        box-shadow: none;
                    }

                    @include tablet {
                        margin-left: 32px;
                    }
                }
            }
        }
    }
}
