.brand-ford {
    .vehicle-health-page-wrapper {
        background-color: $fds-color-surface;
        .breadcrumbs-common {
            @include mobile {
                width: 90%;
                padding-right: 30px;
            }
            .breadcrumbs-content {
                @include mobile {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: $fds-color-disabled-dark;
                }
            }
        }
        .feature-trio {
            padding: 0 20px 20px 20px;
            margin-top: 0;
            margin-bottom: 0;

            .feature-trio-heading-two {
                h2 {
                    text-transform: uppercase;
                    font-family: $f1-font-light;
                }
            }
            .feature-trio-cards {
                margin-top: 40px;
            }
        }

        .breadcrumbAccordion {
            background-color: $fds-color-lt-gray;

            .fds-chevron-wrap {
                display: none;
                @include mobile {
                    display: block;
                    line-height: 70px;
                }
            }
        }
        @include mobile {
            .breadcrumbAccordion {
                display: flex;
                .hide {
                    display: none;
                }
                .show {
                    display: block;
                }
                &.expanded {
                    .breadcrumbs {
                        .breadcrumbs-content {
                            overflow: visible;
                            text-overflow: none;
                            white-space: normal;
                        }
                    }
                }
            }
        }

        .vehicle-health-page {
            margin: 3.5% 5% 1.5% 5%;

            .vehicle-health-top-buttons {
                display: flex;
                justify-content: space-between;
            }

            .vehicle-health-download-a-copy {
                margin-left: auto;
                @include mobile {
                    align-items: center;
                }
                @include print {
                    display: none !important;
                }

                a.primary-button {
                    @include desktop {
                        padding-right: 0px;
                    }
                    @include mobile {
                        justify-content: center;
                        margin-bottom: 30px;
                    }

                    .vehicle-health-download-button-format {
                        display: flex;
                        align-items: center;
                        color: $fds-color-dk-gray;
                        font-size: 16px;
                        font-family: $f1-font-regular;

                        .vehicle-health-download-icon {
                            width: pToR(24);
                            height: pToR(24);
                            margin-right: pToR(8);
                        }
                    }
                }
            }

            .vehicle-health-wrapper {
                padding: 0 20px;
                text-align: center;
                color: $dropdown-text;
                margin-bottom: 10px;

                @include mobile {
                    padding: 0 30px;
                }
                .vh-vehicle-details {
                    font-size: 16px;
                    line-height: 26px;
                    text-transform: uppercase;
                    font-family: $f1-font-regular;
                }
                .vh-title {
                    font-size: 48px;
                    line-height: 58px;
                    font-family: $f1-font-light;
                    margin: 0px 0px 24px;
                    @include mobile {
                        font-size: 45px;
                    }
                }

                .vh-description,
                .vh-lastUpdated {
                    font-size: 16px;
                    line-height: 26px;
                    font-family: $f1-font-regular;
                }

                .vh-alerts {
                    text-transform: uppercase;
                    color: $fds-color-primary;
                    font-size: 24px;
                    margin-bottom: 24px;
                    font-family: $f1-font-bold;

                    img {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .vh-alert-text {
                        padding-left: 10px;
                        display: inline-block;
                        vertical-align: text-top;
                    }
                }

                .vh-cta {
                    margin: 30px auto;
                    display: inline-block;
                    text-align: center;

                    a {
                        font-size: 18px;
                        line-height: 13px;
                        font-family: $f1-font-regular;
                    }
                }
            }
            .vha-accordion-section {
                margin-top: 25px;

                div:last-child {
                    .accordion-title {
                        border-bottom: 1px solid $fds-color-overlay;
                    }
                }
                .vha-oillife-accordion,
                .vha-recall-accordion {
                    .oil-life-heading,
                    .recall-heading {
                        display: flex;
                        align-items: center;

                        img {
                            width: 24px;
                            height: 24px;
                        }

                        span {
                            margin-left: 12px;
                            font-size: 24px;
                            font-family: $f1-font-bold;
                            color: $fds-color-primary;
                            position: relative;
                            top: 2px;

                            @include mobile {
                                font-size: 16px;
                                top: 3px;
                            }
                        }
                    }
                    .panel {
                        padding: 15px 35px;
                        text-align: left;
                        line-height: 32px;
                        .recall-panel-header {
                            p {
                                font-size: 16px;

                                @include mobile {
                                    line-height: 22px;
                                }
                            }
                        }

                        .recall-panel {
                            padding-right: 10px;
                            @include mobile {
                                padding-top: 10px;
                            }
                            .recall-warning-icon {
                                padding-right: 10px;
                            }
                        }
                    }
                }
            }
            img {
                &.good-icon {
                    content: url('../../../assets/green-checkmark.svg');
                }
                &.warning-icon {
                    content: url('../../../assets/warning-filled.png');
                }
            }
        }
        .smash-card {
            margin-bottom: 75px;
            @include mobile {
                margin-bottom: 0;
            }
            .smash-card__body-headline,
            .smash-card__body-description {
                @include mobile {
                    text-align: left;
                }
            }
            .smash-card__body-cta {
                background: transparent;
                color: white;
                border: none;
                margin-bottom: 25px;
                @include mobile {
                    margin-right: auto;
                }

                &:before {
                    background-color: $dark-blue;
                    border-radius: 32px;
                    border: none;
                    box-shadow: 0 1rem 1rem 0 rgba(black, 0.1),
                        0 2rem 2rem 0 rgba(black, 0.1);
                }
                &:hover {
                    &:before {
                        background-color: #1700f4;
                    }
                }
            }
        }
        .vh-article-carousel {
            .vh-article-carousel-header {
                margin: 64px 16px 0 16px;
                @include desktop {
                    margin: 96px 16px 0 56px;
                }

                .fm-vehicle-health-carousel__title {
                    font-size: 40px;
                    line-height: 48px;
                    color: $fm-text-grey;
                    font-family: $f1-font-regular;
                }

                .fm-vehicle-health-carousel__subtitle {
                    margin: 16px 16px 0 0;
                    font-size: 16px;
                    line-height: 24px;
                    color: $f1-text-blue;
                    font-family: $f1-font-regular;
                }
            }
            .fm-carousel__container {
                background: transparent;
                margin-top: 0;
                .fm-card__container {
                    color: $fm-text-grey;
                    font-family: $f1-font-regular;
                    .fm-card__content--container {
                        .fm-card__title {
                            font-size: 32px;
                            line-height: 40px;
                        }
                        .fm-card__description {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .fm-btn.fm-btn--secondary.fm-btn__light {
                        color: $brand-white;
                        background-color: #066fef;
                    }
                }
            }
        }
    }
    .search-bar {
        margin-top: 0;
    }
    .support-section {
        .feature-trio {
            .feature-trio-title {
                padding-top: 10px;
            }
        }
    }
}
