@import '../../../../../styles/main.scss';

.tire-finder-banner-top {
    background-position: center;
    background-size: cover;
    color: $fds-color-white;
    font-family: $f1-font-regular;
    width: 100%;
    margin: auto;
    height: 503px;

    @include desktop {
        height: 350px;
    }

    @media print {
        display: none;
    }
}

.tire-finder-banner-cta {
    text-align: center;
    margin: 0;
    @media (min-width: 1158px) {
        max-width: 100%;
        margin: 0 pToR(18);
    }
}
