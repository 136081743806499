.brand-lincoln {
    .vehicle-health-page-wrapper {
        .breadcrumbs-common {
            font-weight: bold;
            @include mobile {
                width: 85%;
                padding-right: 50px;
                padding-left: 40px;
            }

            .breadcrumbs-content {
                display: block;

                @include mobile {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: $fds-color-disabled-dark;
                }

                .breadcrumb-link,
                .back-slash {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;

                    @include desktop {
                        font-size: 20px;
                    }
                }
                .current-page {
                    font-family: $proxima-nova-regular;
                    color: $brand-mild-gray1;

                    @include desktop {
                        font-size: 20px;
                    }
                }
            }
        }
        .smash-card {
            margin-bottom: 75px;

            @include mobile {
                margin-bottom: 0;
            }
        }
        .feature-trio {
            padding: 0 20px 20px 20px;
            margin-top: 0;
            margin-bottom: 0;
            .feature-trio-heading-two {
                h2 {
                    text-transform: uppercase;
                    font-family: $proxima-nova-light;
                    color: $fds-color-dk-gray;
                }
                .secondary-button {
                    .fds-chevron-wrap {
                        .fds-arrow.filled {
                            margin-left: 0px;
                        }
                    }
                }
            }
            .feature-trio-cards {
                margin-top: 40px;
                .card-body {
                    h3 {
                        font-family: $proxima-nova-light;
                        color: $brand-mild-gray2;
                        text-align: left;
                    }
                }
            }
        }
        .category-5050-carousel-container {
            padding-top: 0;
            padding-bottom: 45px;
            margin-top: 25px;
            margin-bottom: 0;

            .category-5050-carousel-heading {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 0px 16px;
                max-width: 90rem;
                border-bottom: 1px solid $border-grey;
            }
            .category-5050-carousel__title {
                font-size: 20px;
                color: $dropdown-text;
                letter-spacing: 3px;
                line-height: 30px;
                text-transform: uppercase;
                text-align: left;
                margin-bottom: 0px;
                padding-top: 19px;
                padding-bottom: 8px;
            }
            .secondary-button {
                display: inline-block;
                padding-top: 25px;
                @include tablet {
                    padding-top: 13px;
                }
                .secondary-button-text {
                    display: none;
                }
                .secondary-button-link {
                    padding: 0px;
                    .fds-chevron-wrap {
                        padding-left: 1rem;
                        .fds-arrow.filled {
                            margin-left: 0px;
                        }
                    }
                }
            }
            .horizontal-card {
                padding-top: 30px;
                .horizontal-card__title {
                    font-size: 24px;
                    line-height: 34px;
                    letter-spacing: 3px;
                    margin-top: 17px;
                    text-align: left;
                    border: 0px;
                    color: $dropdown-text;
                }
                .horizontal-card__content-wrapper {
                    min-height: 290px;
                    padding: 0px 16px;
                }
                .horizontal-card__divider {
                    display: none;
                }
                .horizontal-card__primary-button {
                    margin-top: 5px;
                }
            }
            .category-5050-carousel__controls {
                .category-5050-carousel-button {
                    padding: 12px 10px;
                    .fds-arrow {
                        font-size: 22px;
                    }
                }
            }
        }
        .breadcrumbAccordion {
            background-color: $fds-color-lt-gray;
            .fds-chevron-wrap {
                display: none;
                @include mobile {
                    display: block;
                    line-height: 70px;
                }
            }
        }
        @include mobile {
            .breadcrumbAccordion {
                display: flex;
                .hide {
                    display: none;
                }
                .show {
                    display: block;
                }
                &.expanded {
                    .breadcrumbs {
                        .breadcrumbs-content {
                            overflow: visible;
                            text-overflow: none;
                            white-space: normal;
                        }
                    }
                }
            }
        }

        .vehicle-health-page {
            margin: 3.5% 5% 1.5% 5%;

            .vehicle-health-top-buttons {
                display: flex;
                justify-content: space-between;
            }

            .vehicle-health-download-a-copy {
                margin-left: auto;
                @include mobile {
                    align-items: center;
                }
                @include print {
                    display: none !important;
                }

                a.primary-button {
                    @include desktop {
                        padding-right: 0px;
                    }
                    @include mobile {
                        justify-content: center;
                        margin-bottom: 30px;
                    }

                    .vehicle-health-download-button-format {
                        display: flex;
                        align-items: center;
                        color: $fds-color-dk-gray;
                        font-size: 16px;
                        letter-spacing: 1px;
                        font-family: $f1-font-regular;

                        .vehicle-health-download-icon {
                            width: pToR(24);
                            height: pToR(24);
                            margin-right: pToR(8);
                        }
                    }
                }
            }

            .vehicle-health-wrapper {
                padding: 0 20px;
                text-align: center;
                color: $dropdown-text;
                margin-bottom: 10px;

                @include mobile {
                    padding: 0 30px;
                }

                .vh-vehicle-details {
                    font-size: 16px;
                    line-height: 26px;
                    text-transform: uppercase;
                    font-family: $f1-font-regular;
                    letter-spacing: 2px;
                }

                .vh-title {
                    font-size: 48px;
                    line-height: 58px;
                    font-family: $f1-font-light;
                    margin: 0px 0px 24px;
                    letter-spacing: 3px;
                    font-weight: 300;
                    @include mobile {
                        font-size: 45px;
                    }
                }

                .vh-description,
                .vh-lastUpdated {
                    font-size: 16px;
                    line-height: 26px;
                    letter-spacing: 1px;
                    font-family: $f1-font-regular;
                }

                .vh-alerts {
                    text-transform: uppercase;
                    color: $brand-secondary;
                    font-size: 24px;
                    margin-bottom: 24px;
                    font-family: $proxima-nova-regular;

                    img {
                        display: inline-block;
                        vertical-align: text-bottom;
                    }

                    .vh-alert-text {
                        padding-left: 10px;
                        display: inline-block;
                        letter-spacing: 3px;
                        vertical-align: text-top;
                    }
                }

                .vh-cta {
                    margin: 30px auto;
                    display: inline-block;
                    text-align: center;

                    a {
                        font-size: 18px;
                        line-height: 13px;
                        letter-spacing: 1px;
                        font-family: $f1-font-regular;
                    }
                }
            }

            .vha-accordion-section {
                margin-top: 25px;

                div:last-child {
                    .accordion-title {
                        border-bottom: 1px solid $brand-lds-color-overlay;
                    }
                }

                .vha-oillife-accordion,
                .vha-recall-accordion {
                    .oil-life-heading,
                    .recall-heading {
                        display: flex;
                        align-items: center;

                        img {
                            width: 24px;
                            height: 24px;
                        }

                        span {
                            margin-left: 12px;
                            letter-spacing: 2px;
                            position: relative;
                            top: 2px;
                            font-family: $proxima-nova-bold;
                            color: $brand-secondary;
                            font-size: 26px;
                        }
                    }
                    .panel {
                        padding: 15px 35px;
                        text-align: left;
                        line-height: 32px;
                        .recall-panel-header {
                            p {
                                font-size: 16px;
                                letter-spacing: 1px;

                                @include mobile {
                                    line-height: 22px;
                                }
                            }
                        }
                        .recall-panel {
                            padding-right: 10px;
                            @include mobile {
                                padding-top: 10px;
                            }
                            .recall-warning-icon {
                                padding-right: 10px;
                            }
                        }
                    }
                }
            }
            img {
                &.good-icon {
                    content: url('../../../assets/lincoln-icons/checkmark.svg');
                }
                &.warning-icon {
                    content: url('../../../assets/lincoln-icons/error-notification.svg');
                }
            }
        }
        .smart-tile-grid-wrapper.information-grid {
            @include desktop {
                padding-top: 30px;
            }
        }
    }
    .search-bar {
        margin-top: 0;
    }
    .support-section {
        .feature-trio {
            .feature-trio-title {
                padding-top: 10px;
            }
        }
    }
}
