@import '../../../../../../styles/main';
.brand-ford {
    .ute-footer-section {
        margin: 24px 0px 40px 0px;
        .ute-trailer-footer {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            align-items: center;
            @include desktop {
                flex-direction: row;
                justify-content: space-between;
            }
            .faq_wrap {
                @include desktop {
                    width: 50%;
                }
                p {
                    font-size: 14px;
                    line-height: 24px;
                    color: $fds-text;
                }
            }
            .ute-cta-button {
                font-family: $f1-font-regular;
                display: flex;
                @include desktop {
                    flex-direction: row;
                    justify-content: flex-end;
                    width: 50%;
                }
                .ute-goBack-button {
                    flex-direction: row-reverse;
                    .button-label {
                        margin-left: 12px;
                        margin-right: 0px;
                    }
                }
                .ute-cancel-button {
                    font-size: 18px;
                    line-height: 24px;
                    color: $fds-color-primary;
                    text-decoration: underline;
                    margin-right: 28px;
                    &::before {
                        box-shadow: none;
                        border: 1px solid $breadcrumb-blue;
                    }
                    &:not(.disabled) {
                        &:hover {
                            &::before {
                                border: 1px solid $breadcrumb-blue;
                            }
                        }
                    }
                }
                .ute-save-button {
                    &::before {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
