@import '../../../../../styles/main.scss';
.brand-ford {
    .diagnostics-details-wrapper {
        padding: 16px 100px 0 90px;
        @include mobile {
            padding: 20px;
        }
        .diagnostics-details-header {
            display: flex;
            justify-content: space-between;
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            font-size: 34px;
            line-height: 40px;

            @include mobile {
                font-size: 28px;
                line-height: 36px;
                text-align: left;
                margin-bottom: 24px;
            }
            .diagnostics-details-download-copy-wrapper {
                cursor: pointer;
                .diagnostics-details-download-copy-icon {
                    width: 30px;
                    height: 25px;
                }
                @include print {
                    display: none !important;
                }
            }
        }
        .alert-color {
            color: $error-message-text !important;
        }
        .warning-color {
            color: $fds-color-orange !important;
        }
        .diagnostics-content-wrapper {
            display: grid;
            grid-template-columns: 20% 30% 20%;
            column-gap: 50px;
            margin-top: 40px;
            @include mobile {
                grid-template-columns: 100%;
                margin-top: 20px;
            }
            .diagnostics-icon-desc {
                .diagnostics-icon-holder {
                    display: flex;
                    justify-content: right;
                    @include mobile {
                        justify-content: center;
                    }
                    .diagnostics-icon {
                        height: 67px;
                        width: 52.82px;
                    }
                }
            }
            .diagnostics-status-wrapper {
                height: 48px;
                width: 398px;
                color: $fds-text;
                font-size: 14px;
                line-height: 24px;
                @include mobile {
                    margin: 20px 0 20px 0;
                    width: 100%;
                }
            }
            .diagnostics-book-service {
                margin-top: 20px;
                @include desktop {
                    margin-left: 30px;
                }
            }
        }
        .diagnostics-accordion-wrapper {
            padding-top: 50px;
            .accordion-section {
                .alert-color {
                    color: $error-message-text !important;
                }
                .warning-color {
                    color: $fds-color-orange !important;
                }
                .whatHappening,
                .whatShouldIdo {
                    padding-top: 20px;
                }
                .accordion-title {
                    color: $fds-color-primary;
                    font-size: 16px;
                    line-height: 24px;
                    .fds-chevron-wrap {
                        color: $fds-color-primary;
                    }
                }
                .panel.expanded {
                    color: $fds-color-primary;
                    margin: 16.5px 16px 40.5px 16px;
                    text-align: left;
                }
            }
        }
        .diagnostics-category-wrapper {
            .category-heading {
                padding-top: 30px;
                padding-bottom: 25px;
                color: #00142e;
            }
            .category-desc {
                padding: 40px 0 50px 0;
                color: #00142e;
                line-height: 24px;
            }
        }
    }
}
