@import '../../../../../styles/lincoln/main.scss';

.brand-lincoln {
    .tire-finder-text {
        width: 100%;
        padding-top: 88px;

        @include tablet {
            padding-top: 88px;
        }

        @include desktop {
            padding-top: 88px;
            width: 100%;
            margin: 0;
        }

        .tire-finder-title {
            color: $white;
            font-family: $lincoln-millerb-roman;
            text-transform: capitalize;
            text-align: center;
            padding-top: 48px;
            font-size: 40px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 48px;
        }

        .tire-finder-description {
            color: $white;
            font-family: $proxima-nova-light;
            margin-top: 24px;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            .tire-finder-description-text {
                margin-left: 5%;
                margin-right: 5%;
                @include tablet {
                    margin-left: 5%;
                    margin-right: 5%;
                }
                @include desktop {
                    margin-left: 20%;
                    margin-right: 20%;
                }
            }
        }
    }
}
