@import '../../../../../styles/main.scss';
.brand-ford {
    .ute-view-trailer-breadcrumb {
        .breadcrumbs {
            padding-left: 0px !important;
        }
    }
    .ute-content {
        margin-bottom: 60px;
    }
    .ute-view-trailer-profile-section {
        .ute-heading-section {
            .ute-header {
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 24px;
                text-transform: uppercase;
                @include tablet {
                    font-size: 40px;
                    line-height: 48px;
                    text-align: left;
                }
            }
        }
        .accordion-title {
            color: $fds-color-white;
            background-color: $fds-color-primary;
        }
        .accordion-divider {
            height: 20px;
        }
        .button-section {
            margin: 40px 0px;
            .edit-btn {
                display: flex;
                align-items: center;
                flex-direction: column-reverse;

                @include tablet {
                    margin-top: 0px;
                    flex-direction: row;
                    justify-content: right;
                }
                .eas-Label {
                    font-size: 11px;
                    margin-top: 12px;
                    line-height: 16px;
                    text-align: center;
                    @include tablet {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}
