@import '../../../../../styles/main.scss';
.brand-ford {
    .secondary-button .secondary-button-link {
        padding: 0px;
    }
    .ute-content {
        margin-bottom: 60px;
    }
    .ute-wrapper-section {
        .ute-heading-section {
            .ute-header {
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 24px;
                text-transform: uppercase;
                @include tablet {
                    font-size: 40px;
                    line-height: 48px;
                    text-align: left;
                }
            }
        }
        p {
            color: $fds-text;
            font-family: $f1-font-regular;
            font-size: 12px;
            line-height: 24px;
            margin-bottom: 12px;
            @include tablet {
                text-align: left;
                font-size: 14px;
            }
        }
        .ute-desc {
            p {
                margin-bottom: 32px;
            }
            @include mobile {
                p {
                    margin-bottom: 14px;
                }
                padding: 0px 10px;
            }
        }
        .ute-desc-error {
            p {
                margin-bottom: 7px;
            }
            @include mobile {
                p {
                    margin-bottom: -31px;
                }
                padding: 0px 10px;
            }
        }
    }
}
