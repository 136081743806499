@import '../../../../../styles/main.scss';
.brand-ford {
    .oil-life-details-wrapper {
        padding: 0 100px 0 100px;
        @include mobile {
            padding: 20px;
        }
        .oil-life-header {
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            font-size: 34px;
            line-height: 40px;
            margin-top: 18px;
            display: flex;
            justify-content: space-between;

            @include mobile {
                font-size: 28px;
                line-height: 36px;
                text-align: left;
                margin-bottom: 24px;
            }
            .oillife-details-download-copy-wrapper {
                cursor: pointer;
                .oillife-details-download-copy-icon {
                    width: 30px;
                    height: 25px;
                }
                @include print {
                    display: none !important;
                }
            }
        }
        .oil-life-content-wrapper {
            display: grid;
            grid-template-columns: 200px 400px 200px;
            column-gap: 30px;
            margin-top: 40px;
            @media screen and (max-width: 1200px) {
                grid-template-columns: 200px 400px;
            }

            @include mobile {
                grid-template-columns: 100%;
            }
            .progress-bar {
                margin-bottom: 20px;
                margin-right: 30px;
            }
            .oil-life-data-wrapper {
                display: flex;
                padding: 20px 0 24px 0;
                .miles-wrapper {
                    margin-right: 30px;
                    margin-left: 10px;
                    .miles {
                        color: $fds-color-primary;
                        font-family: $f1-font-regular;
                        font-size: 28px;
                        line-height: 36px;
                        text-align: center;
                    }
                    .miles-label {
                        color: $fds-color-disabled-light;
                        font-family: $f1-font-regular;
                        font-size: 14px;
                        line-height: 24px;
                        text-align: center;
                        white-space: nowrap;
                    }
                }
                .divider-line {
                    height: 72px;
                    width: 1px;
                    opacity: 0.4;
                    transform: scaleX(-1);
                    background-color: $fds-color-black;
                }
                .service-due-wrapper {
                    margin-left: 30px;
                    .service-date {
                        color: $fds-color-primary;
                        font-family: $f1-font-regular;
                        font-size: 28px;
                        line-height: 36px;
                        text-align: center;
                        white-space: nowrap;
                    }
                    .service-date-label {
                        color: $fds-color-disabled-light;
                        font-family: $f1-font-regular;
                        font-size: 14px;
                        line-height: 24px;
                        text-align: center;
                        white-space: nowrap;
                    }
                }
            }
            .oil-life-book-service {
                margin-top: 20px;
            }
        }
        .oil-life-text {
            color: $fds-text;
            font-family: $f1-font-regular;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 30px;
            margin-top: 24px;
            width: 65%;
            @include mobile {
                width: 100%;
            }
        }

        .oil-life-accordion {
            margin-bottom: 40px;
            .accordion-section {
                .accordion-title {
                    color: $fds-color-primary;
                    font-size: 16px;
                    line-height: 24px;
                    .fds-chevron-wrap {
                        color: $fds-color-primary;
                    }
                }
                .panel.expanded {
                    color: $fds-color-primary;
                    margin: 16.5px 16px 40.5px 16px;
                    text-align: left;
                    line-height: 24px;
                }
            }
        }
    }
}
