@import '../../../../styles/main.scss';

.preferred-dealer-section {
    background-color: $white;
    flex-direction: column;
    display: flex;
    padding-bottom: 0px !important;
    @include desktop {
        height: 335px;
        flex-direction: row;
        width: 100%;
    }

    .dealer-content-headline {
        width: 100%;
        height: 245px;
        background-color: #00095b;
        @include tablet {
            height: 335px;
        }
        @include desktop {
            width: 25%;
            height: 335px;
        }

        .title {
            height: 127px;
            color: $white;
            font-family: $f1-font-light;
            font-size: 34px;
            line-height: 58px;
            text-align: center;
            margin: 86px 18px 0px 18px;

            @include mobile {
                height: 126px;
                margin: 27px 18px 0px 25px;
            }
        }

        .subtitle {
            height: 26px;
            color: $white;
            font-family: $f1-font-light;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            margin-bottom: 0;
            margin-left: 50px;
            margin-right: 52px;
            padding: 0;
            @include mobile {
                margin-left: 72px;
                margin-right: 77px;
                margin-top: 0px;
            }
        }
    }

    .dealer-information {
        width: 100%;
        display: flex;
        flex-direction: column;
        @include desktop {
            width: 30%;
        }
        .dealer-rating {
            width: 100%;
            height: 75px;
            border-bottom: 0.63px solid #bfbfbf;
            transform: rotate(-359.97deg);
            padding-top: 20px;
            @include desktop {
                height: 25%;
            }

            .rating {
                height: 26px;
                color: #4d4d4d;
                font-family: $f1-font-light;
                font-size: 16px;
                line-height: 26px;
                margin: 10px 0 0 51px;
            }
        }
        .dealer-details-wrapper {
            height: 75%;
            .dealer-address {
                height: 75px;
                width: 100%;
                color: #4d4d4d;
                font-family: $f1-font-light;
                font-size: 16px;
                line-height: 26px;
                display: flex;
                align-items: center;
                padding-top: 10px;
                @include desktop {
                    padding-top: 20px;
                    height: 40%;
                }
                .space {
                    width: 51px;
                }
                .details {
                    border-bottom: 0.63px solid #bfbfbf;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    .icon {
                        margin-top: 5px;
                        width: 16px;
                        height: 16px;
                        margin-right: 15px;
                    }
                }
            }
            .dealer-phone-number {
                display: flex;
                align-items: column;
                width: 100%;
                height: 75px;
                color: #4d4d4d;
                font-family: $f1-font-light;
                font-size: 16px;
                line-height: 26px;
                @include desktop {
                    height: 30%;
                }
                .space {
                    width: 51px;
                }
                .details {
                    border-bottom: 0.63px solid #bfbfbf;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .icon {
                        width: 16px;
                        height: 16px;
                        margin-right: 15px;
                    }
                }
            }
            .dealer-buttons {
                height: 30%;
                height: 75px;
                width: 50%;
            }
        }
        .dealer-location-map {
            display: block;
            @include mobile {
                display: none;
            }
            @include tablet {
                display: none;
            }
        }
    }
}
