@import '../../../../../styles/main.scss';
.brand-ford {
    .vha-card-wrapper {
        @media screen and (min-width: 1400px) {
            width: 397px;
        }
        width: 355px;
        min-height: 92px;
        border-radius: 3px;
        background-color: $fds-color-white;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        @include mobile {
            width: 100%;
        }
        .vha-card-link {
            display: flex;
            cursor: pointer;
            padding: 20px;
            .card-content {
                flex: 1;
                .icon-title-wrapper {
                    display: flex;
                    font-size: inherit;
                    .card-icon {
                        .card-icon-style {
                            width: 24px;
                            height: 21px;
                        }
                    }
                    .card-title {
                        color: $fds-color-primary;
                        font-family: $f1-font-regular;
                        font-size: 20px;
                        line-height: 24px;
                        margin-left: 4px;
                    }
                }
                .card-description {
                    font-size: 16px;
                    line-height: 24px;
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-regular;
                    text-align: left;
                    margin-top: 8px;
                }
            }
            .arrow-right {
                align-self: center;
                .fds-chevron .fds-arrow {
                    color: $fds-color-primary;
                    font-size: 1.25rem;
                }
            }
        }
    }
    .vha-card-wrapper::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 8px;
        border-radius: 3px 3px 0 0;
        left: 0;
        top: 0;
    }
    .vha-card-wrapper--alert::after {
        background-color: $error-message-text;
    }
    .vha-card-wrapper--warning::after {
        background-color: $fds-color-orange;
    }
}
