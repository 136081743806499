@import '../../../../../styles/main';

.brand-ford {
    .ute-vehicle-selector-main {
        .vin-status {
            display: flex;
            gap: 24px;
            padding: 16px 16px;
            background-color: $fds-color-primary;
            box-shadow: 0 12px 10px 5px rgba(0, 0, 0, 0.12);

            @include mobile {
                margin: 0px 10px;
                position: relative;
                top: 28px;
            }

            .message {
                font-size: 11px;
                line-height: 16px;
                color: $white;
            }
        }
        .ute-vin-input-section {
            padding: 25px 50px 62px 50px;
            box-shadow: 0 12px 10px 5px rgba(0, 0, 0, 0.12);
            box-sizing: border-box;

            @include mobile {
                justify-content: center;
                margin-top: 30px;
                height: auto;
                box-shadow: 0 12px 10px -5px rgba(0, 0, 0, 0.12);
                padding: 10px;
            }

            .ute-garage-section {
                display: inline-block;
                padding: 0 50px 0 0;
                flex: 1;

                .heading-with-icon {
                    min-height: 55px;
                    @include mobile {
                        min-height: 45px;
                    }
                }
                .dropdown-container-wrapper {
                    padding-top: 0;
                    .ute-garage-dropdown-section {
                        margin-top: 16px;

                        .dropdown-button {
                            background-color: $fds-color-white;
                            box-sizing: border-box;
                            height: 50px;
                            border: 1px solid $fds-color-md-gray;
                            border-radius: 3px;
                        }

                        @include mobile {
                            margin-top: 0;
                        }
                    }
                }
                @include mobile {
                    width: 100%;
                    display: block;
                    padding: 0;
                }
            }

            .heading-with-icon {
                @include mobile {
                    text-align: left;
                }
            }

            h4,
            h2 {
                display: inline;
                font-size: 16px;
                line-height: 1.29;
                font-family: $f1-font-regular;
                font-stretch: normal;
                font-style: normal;
                color: $fds-color-primary;

                @include mobile {
                    font-size: 13px;
                    line-height: 1.49;
                    text-align: left;
                }
            }

            .info-wrapper {
                display: inline;
                position: relative;

                img {
                    display: inline;
                    position: absolute;
                    left: 8px;
                    width: 20px;
                    height: 20px;
                }
            }

            label {
                display: block;
                padding: 0 0 10px 0;
                font-size: 11px;
                margin-top: 19px;
                margin-bottom: 8px;
            }

            .divider-section {
                display: block;
                width: auto;
                position: relative;
                color: $fds-color-dk-gray;
                align-self: center;
                margin: 45px 68px 40px auto;

                @include mobile {
                    margin: 0 68px 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }

                span {
                    padding-left: 30px;

                    @include mobile {
                        padding: 15px;
                    }
                }

                hr {
                    position: absolute;
                    width: 18px;
                    border: 0.1px solid $fds-color-dk-gray;
                    box-shadow: none;

                    @include mobile {
                        position: unset;
                        margin: 0;
                    }

                    &.before-dash {
                        top: 9px;
                        display: inline-block;
                        @include mobile {
                            display: none;
                        }
                    }

                    &.after-dash {
                        margin-left: 9px;
                        top: 9px;
                        display: inline-block;

                        @include mobile {
                            margin-left: 0px;
                            display: none;
                        }
                    }
                }
            }

            .see-your-warranty-button.vin {
                margin-top: 27px;
            }
            .see-your-warranty-button {
                margin-top: 30px;
                margin-left: 100px;

                .button-label {
                    margin: 0 15px 0 10px;
                }

                @include mobile {
                    margin: 30px auto;
                    margin-bottom: 10px;
                }
            }
            .vehicle-details-wrapper .enter-your-vin-section label {
                img.error-icon {
                    width: 24px;
                    top: 1px;
                }
                @include mobile {
                    margin: 0;

                    img.error-icon {
                        width: 16px;
                    }
                }
            }
            .vehicle-details-wrapper {
                display: flex;
                flex-wrap: wrap;
                @include mobile {
                    flex-direction: column;
                }

                .enter-your-vin-section {
                    display: inline-block;
                    flex: 1;

                    .input-field-container.error {
                        input[value=''] + label {
                            color: $fds-color-dk-gray;
                        }
                        &:focus-within {
                            input[value=''] + label {
                                color: $dropdown-text;
                                top: -6px;
                            }
                        }
                    }

                    input[value=''] + label {
                        top: -19px;
                        left: 0px;
                        font-size: 11px;
                        cursor: text;

                        @include desktop {
                            top: -6px;
                            .error-icon.empty {
                                width: 22px;
                            }
                        }
                    }

                    input[value=''] + img.error-icon {
                        .error-icon {
                            width: 22px;
                        }
                    }

                    .error-icon.empty {
                        position: absolute;
                        top: 9px;
                        left: 101px;
                        @include desktop {
                            top: -10px;
                            left: 74px;
                        }
                    }

                    .error:focus-within {
                        border-color: $brand-border;
                        label.input-label {
                            top: 0px;
                            left: 0px;
                            font-size: 11px;
                            display: inline;
                            color: $dropdown-text;
                            @include desktop {
                                top: -12px;
                            }
                        }
                    }

                    &:focus-within {
                        border-color: $brand-border;
                        label.input-label {
                            top: 0px;
                            left: 0px;
                            font-size: 11px;
                            display: block;

                            @include desktop {
                                top: -12px;
                            }
                        }
                    }

                    @include mobile {
                        width: 100%;
                        text-align: left;
                        padding-left: 0;

                        .heading-with-icon {
                            margin-top: 10px;
                        }

                        label {
                            margin-top: 15px;
                            margin-bottom: -14px;
                            text-align: left;
                            img.error-icon {
                                width: 18px;
                            }
                        }
                    }
                }

                .vin-textField {
                    width: 100%;
                    box-sizing: border-box;
                    height: 50px;
                    border: 1px solid $fds-color-md-gray;
                    border-radius: 3px;

                    @include desktop {
                        max-width: 450px;
                    }
                }

                .input-field-error {
                    border-color: $error-text;
                    border-radius: pToR(1);
                }

                .error-message {
                    white-space: normal;
                    margin-left: 11px;
                }
            }
        }
    }
}
