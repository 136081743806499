.brand-ford {
    .trailer-checklist-view {
        min-height: 100vh;
    }
    .trailer-checklist-page {
        margin: 5% 7% 5% 103px;
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;
        @include mobile {
            margin: 0px;
            padding: 0px;
        }
        .service-and-offers-section {
            margin: 70px 0px 0px 0px;
            padding: 0px;
            .feature-trio {
                padding: 0px;
                max-width: 100%;
            }
        }
        .trailer-checklist-header {
            font-size: 40px;
            line-height: 48px;
            font-family: $f1-font-regular;
            color: $breadcrumb-blue;
            margin-bottom: 16px;
            @include mobile {
                text-align: center;
                margin-bottom: 60px;
            }
        }

        .trailer-checklist-description {
            font-size: 16px;
            line-height: 26px;
            @include mobile {
                text-align: center;
                margin: 0px 27px;
            }
        }
        .trailer-checklist-cta-button-wrapper {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-top: 54px;

            @include mobile {
                align-items: center;
            }
            .trailer-checklist-cta-button {
                margin: 18px 0px;
                border: 1px solid $breadcrumb-blue;
                border-radius: 20px;

                &::before {
                    box-shadow: none;
                    background-color: #f4f4f4;
                }
                &:hover {
                    &::before {
                        border: 1px solid $breadcrumb-blue;
                    }
                }
            }
        }
    }
}
