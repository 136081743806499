@import '../../../../../styles/main.scss';
.brand-ford {
    .ute-faq-wrapper-section {
        .ute-faq-heading-section {
            .ute-header {
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 24px;
                @include tablet {
                    font-size: 40px;
                    line-height: 48px;
                    text-align: left;
                }
            }
        }
        .ute-faq-details-section {
            text-align: center;
            margin-top: 100px;
            font-family: $f1-font-regular;
            .coming-soon {
                color: $fds-color-primary;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
            }
            .description {
                margin-top: 24px;
                color: $fds-color-md-gray;
                font-size: 16px;
                line-height: 24px;
            }
            .back-btn {
                margin-top: 53px;
                .fds-chevron-wrap {
                    padding: 15px;
                }
            }
        }
    }
}
