@import '../../../../../styles/main.scss';
.brand-ford {
    .alert-list-wrapper {
        padding: 16px 0 0 100px;
        @include mobile {
            padding: 16px 20px 0 20px;
            text-align: left;
        }

        .alert-list-header {
            font-size: 34px;
            line-height: 40px;
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            @include mobile {
                font-size: 20px;
                line-height: 24px;
            }
        }
        .status-text {
            font-size: 24px;
            line-height: 28px;
            color: $fds-color-green;
            font-family: $f1-font-regular;
            margin-top: 41px;
            @include mobile {
                font-size: 20px;
                margin-top: 16px;
            }
        }
        .alert-status-text {
            color: $error-message-text;
        }
        .warning-status-text {
            color: $fds-color-orange;
        }
        .normal-status-text {
            color: $fds-color-green;
        }
        .last-updated-text {
            color: $fds-text;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
            margin-top: 27px;
            @include mobile {
                margin-top: 16px;
            }
        }
        .concerns-text {
            color: $fds-text;
            font-family: $f1-font-regular;
            font-size: 14px;
            line-height: 24px;
            margin-top: 4px;
            width: 587px;
            @include mobile {
                width: 100%;
                margin-top: 8px;
            }
        }
        .cards-list {
            display: grid;
            grid-template-columns: 31% 31% 31%;
            column-gap: 24px;
            row-gap: 20px;
            margin: 48px 0 25px 0;
            @include mobile {
                margin-top: 18px;
                grid-template-columns: 100%;
            }
            @media screen and (min-width: 768px) and (max-width: 1250px) {
                grid-template-columns: 35% 35%;
            }
        }
        .vha-list-book-service {
            display: flex;
            margin-top: 35px;
            @include mobile {
                margin-top: 25px;
            }
            .link-wrapper-desktop {
                display: flex;
                margin-bottom: 10px;
            }
        }
    }

    .vha-download-copy-wrapper {
        margin: 5px 0 0 30px;
        display: flex;
        cursor: pointer;
        color: $fds-color-primary;
        font-size: 18px;
        line-height: 24px;
        font-family: $f1-font-regular;
        @media only screen and (max-width: 410px) {
            margin: 5px 0 0 0;
        }
        .vha-download-copy-icon {
            width: 24px;
            height: 24px;
            margin-top: 10px;
            @include mobile {
                margin-top: 0;
            }
        }
        .vha-download-copy-label {
            margin: 10px 0 0 8px;
            width: 200px;
            @include mobile {
                margin-top: 0;
            }
        }
        @include print {
            display: none !important;
        }
    }

    .link-wrapper-mobile {
        display: flex;
        margin-top: 15px;
        @media only screen and (max-width: 410px) {
            flex-direction: column;
            .vhr-schedule-report-cta {
                display: block;
            }
        }
    }

    .book-service-cta {
        @include print {
            display: none !important;
        }
    }

    .vhr-schedule-report-cta {
        margin-left: 85px;
        @include mobile {
            margin-left: 0;
            .schedule-report-btn {
                .primary-button {
                    padding: 0;
                }
            }
        }
    }

    .vhr-schedule-report {
        .vhr-schedule-report-button {
            font-size: 18px;
            line-height: 24px;
            font-family: $f1-font-regular;
            text-decoration: none;
        }
    }
}
