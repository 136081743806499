@import '../../../../../styles/_colors';

.ute-data-list {
    .ute-data-list-header {
        display: flex;
        color: $fds-text;
        font-size: 16px;
        line-height: 24px;
        padding-left: 16px;
        border-bottom: 0;

        @include tablet {
            padding-left: 0;
        }

        img {
            margin-left: 0;
            margin-right: 5px;
        }
    }

    .row {
        display: flex;
        border-bottom: 1px solid $fds-color-primary;
        background-color: white;
        padding: 8px 20px;

        .key,
        .value {
            color: $fds-color-dk-gray;
            text-align: left;
            font-size: 12px;
            line-height: 24px;
            flex-grow: 1;
        }

        .value {
            text-align: right;
            color: $fds-color-secondary;
            flex-shrink: 0;
        }
    }
}
