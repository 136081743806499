@import '../../../../../../styles/main';

.brand-ford {
    .ute-create-trailer-profile {
        .ute-heading-section {
            .ute-header {
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 0px;
                text-transform: uppercase;
                @include tablet {
                    font-size: 40px;
                    line-height: 48px;
                    text-align: left;
                }
                span {
                    font-size: 12px;
                    line-height: 24px;
                    @include tablet {
                        font-size: 28px;
                        line-height: 48px;
                    }
                }
            }
        }
        .ute-basic-page {
            display: flex;
            flex-direction: column;
            .ute-input-container {
                margin-bottom: 10px;
                .error-message {
                    color: $fds-color-red;
                }
            }
            @include desktop {
                width: 376px;

                .ute-trailer-nickname-field {
                    grid-column: 1;
                }
            }

            div.input-field-container.fds-style {
                .success-icon {
                    display: none;
                }
                .error-message {
                    top: 128px;
                }
            }
            .create-form-textField {
                top: 30px;
                width: 100%;
                @include desktop {
                    width: 100%;
                }
            }
        }
        .autocomplete-section {
            margin-top: 20px;
            & > div {
                margin-bottom: 20px;
            }
            .autocomplete__wrapper {
                @include desktop {
                    width: 376px;
                }
                margin-bottom: 0;
                border-radius: 0;
                .autocomplete__input {
                    border-radius: 0;
                }
                .autocomplete__menu {
                    color: #0d0e15;
                }
                .autocomplete__option:hover,
                .autocomplete__option--focused {
                    background-color: #6e6e6e;
                }
            }
            .error-message {
                color: $fds-color-red;
                margin-bottom: 12px;
            }
            .error-hide {
                display: none;
            }
            .mfgName,
            .mfgMake,
            .mfgModel,
            .mfgYear {
                font-size: 12px;
                padding-bottom: 10px;
                &.hide {
                    display: none;
                }
            }
        }
        .error-wrapper {
            .autocomplete__wrapper {
                input {
                    border: 1px solid $fds-color-red;
                }
            }
        }
    }
}
