.brand-ford {
    .preferred-dealer-section {
        padding-bottom: 80px;
        @include mobile {
            padding-bottom: 60px;
        }
    }
    .smart-tile-grid-wrapper {
        .inner-smart-tile-grid {
            @include mobile {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                margin-bottom: 0;
            }
        }
    }
    .category-quiet-card__content-wrap__title {
        @include mobile {
            margin-bottom: 30px;
        }
    }
    .vehicle-health-report-page,
    .vhr-page-disclaimer {
        margin: 0 5% 1.5% 5%;
        @include mobile {
            margin: 60px 25px 0 25px;
        }
        .vhr-page-header {
            display: flex;
            flex-direction: column;
            @include tablet {
                padding-top: 20px;
            }
            .print-header {
                .print-header-ford-logo {
                    content: url('../../../assets/ford-logo.svg');
                }
            }
        }
    }
    .vhr-page-error {
        text-align: left !important;
        .error-message {
            @include desktop {
                margin: 0 !important;
            }
        }
        .vhr-error {
            margin-bottom: 10%;
            p {
                @include mobile {
                    white-space: normal;
                }
            }
            @include mobile {
                margin-right: 5px;
                margin-left: 5px;
            }
        }
    }

    .vhr-download {
        float: right;
        @include tablet {
            padding-right: 0px;
        }

        &.desktop-only {
            margin: auto 0 30px;
        }
        .vhr-download-icon {
            content: url('../../../assets/download-icon-solid.svg');
            width: pToR(24);
            height: pToR(24);
            margin-right: pToR(8);
        }

        .vhr-download-button {
            display: flex;
            align-items: center;
            color: $fds-color-primary;
            font-size: 16px;
            font-family: $f1-font-regular;
            text-decoration: underline;
        }
    }

    .vhr-schedule-report {
        @include tablet {
            float: right;
            bottom: -10px;
            padding-left: 0px;
            padding-right: 0px;
        }

        .vhr-schedule-report-icon {
            content: url('../../../assets/report.PNG');
            width: pToR(24);
            height: pToR(24);
            margin-right: pToR(8);
        }

        .vhr-schedule-report-button {
            display: flex;
            align-items: center;
            color: $fds-color-primary;
            font-size: 16px;
            font-family: $f1-font-regular;
            text-decoration: underline;
        }
    }

    .vhr-page-title {
        margin: 0 auto;
        text-align: center;

        h1 {
            height: 40px;
            font-size: 34px;
            line-height: 1.29;
            font-family: $f1-font-light;
            color: $fds-color-dk-gray;
            margin-bottom: 16px;
            @include mobile {
                height: auto;
                font-size: 34px;
                line-height: 44px;
                text-align: center;
            }
        }

        .vehicle-name {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.63;
            font-family: $f1-font-light;
            color: $fds-color-dk-gray;
        }

        .vhr-title-subtext {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.63;
            font-family: $f1-font-regular;
            color: $fds-color-dk-gray;
        }
    }

    .vhr-download-cta {
        display: flex;
        padding: 10px;
        justify-content: center;
        @include tablet {
            padding-right: 0px;
            display: inline;
        }
    }
    .vhr-page-content {
        padding-top: 25px;

        p {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.63;
            font-family: $f1-font-regular;
            color: $fds-color-dk-gray;
        }
        .note,
        .note p {
            font-size: 12px;
            line-height: 2.17;
        }
    }
    .vhr-alert-notification {
        display: block;
        margin-bottom: 50px;
        text-align: center;

        .vhr-alert-notification-icon {
            width: pToR(24);
            height: pToR(24);
            margin-right: pToR(8);
            position: relative;
            top: 3px;
        }

        .vhr-alert-notification-title {
            text-align: left;
            padding-left: 0.5rem;
            color: $fds-color-primary;
            font-family: $f1-font-bold;
            font-size: 24px;
            line-height: 1.42px;
        }

        .vhr-cta-container {
            margin-top: 20px;
            @include mobile {
                margin-top: 25px;
                margin-bottom: 30px;
            }
            .primary-button {
                display: inline-flex;
            }
        }
    }

    .vhr-accordion-section {
        margin-top: 25px;
        margin-bottom: 80px;
        @include mobile {
            margin-top: 30px;
            margin-bottom: 60px;
        }

        .vhr-section-title {
            display: flex;
            margin: 30px 0;
            .vhr-accordion-icon {
                img {
                    content: url('../../../assets/warning-filled.png');
                }
            }

            h2 {
                display: inline;
                flex-basis: 90%;
                font-weight: normal;
                line-height: 1;
                color: $fds-color-primary;
                margin-bottom: 0;
            }
        }

        .vhr-section-data {
            padding: 0 0 60px 60px;

            @include mobile {
                padding-left: 30px;
            }
        }

        .accordion-section .accordion-title {
            align-items: flex-start;
            padding: 12px 24px 16px;
            justify-content: normal;
        }
        .vhr-accordion-icon {
            width: 25px;
            height: 1.5rem;
            flex-basis: 0%;
            margin-right: 12px;
            text-align: left;
        }
        .vhr-accordion-title {
            text-align: left;
            font-family: $f1-font-regular;
            flex-basis: 95%;
            h3 {
                font-size: 16px;
                font-weight: normal;
                line-height: 1.63;
                color: $fds-color-primary;
            }

            span {
                font-size: 11px;
                font-weight: normal;
                line-height: 1.91;
                color: $fds-color-dk-gray;
            }
        }
        .vhr-accordion-details {
            text-align: left;
            background-color: $fds-color-surface;
            color: $fds-color-dk-gray;
            padding: 13px 24px 24px;
            font-size: 16px;
            .warning {
                .customer-action {
                    padding-top: 10px;
                }
            }
        }
    }
    .vehicle-health-report-view {
        .category-quiet-card {
            margin-top: 80px;
            background-color: $fds-color-white;
            @include mobile {
                margin-top: 60px;
                margin-bottom: 30px;
            }
            .primary-button {
                display: inline-flex;
            }
        }

        .category-quiet-card__content-wrap {
            padding: 40px 117px;

            @include mobile {
                padding: 30px;
            }
        }
    }

    .rewards-section {
        padding: 0 117px 80px 117px;

        @include mobile {
            padding: 0 20px 60px 20px;
            margin: 0;
        }

        h2 {
            font-family: $f1-font-light;
            text-transform: uppercase;
            font-size: pToR(34);
            line-height: 58px;
            margin: 0 0 20px 0;
            padding: 0;
            text-align: center;
            color: $fds-color-dk-gray;
        }

        .rewards-3col-container {
            display: flex;
            justify-content: center;
            column-gap: 50px;

            @include mobile {
                flex-direction: column;
            }

            div {
                width: 30%;
                padding: 30px;
                text-align: center;

                @include mobile {
                    width: 100%;
                }

                .icon {
                    padding-bottom: 30px;
                    height: 100px;
                }
            }
        }

        p {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.63;
            font-family: $f1-font-regular;
            color: $fds-color-dk-gray;
        }

        .note {
            p {
                font-size: 12px;
                line-height: 2.17;
            }
        }

        .rewards-cta-container {
            width: 100%;
            text-align: center;
            margin-bottom: 30px;

            .primary-button {
                display: inline-flex;
            }
        }
    }

    .vhr-preferences-section {
        padding: 0 117px 20px;
        margin-bottom: 0;
        @include mobile {
            padding: 0 20px;
            margin: 0;
        }

        h2 {
            font-family: $f1-font-light;
            text-transform: uppercase;
            font-size: pToR(34);
            line-height: 58px;
            margin: 20px 0;
            padding: 0;
            text-align: center;
            color: $fds-color-dk-gray;
            @include mobile {
                margin-bottom: 0;
            }
        }

        p {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.63;
            font-family: $f1-font-regular;
            color: $fds-color-dk-gray;
        }
        .smart-tile-icon {
            .smart-tile-header + span {
                margin-top: 30px !important;
            }
        }
        .labeled-number .icon {
            padding-bottom: 0 !important;
            margin-top: 15px !important;
            margin-bottom: 17px !important;
        }
    }

    .mobile-only {
        @include desktop {
            display: none;
        }
    }

    .desktop-only {
        @include mobile {
            display: none;
        }
    }

    .vhr-page-disclaimer {
        p {
            font-size: 12px;
            line-height: 2.17;
            color: $fds-color-dk-gray;
            font-weight: normal;
        }
    }

    .vhr-service-section,
    .offers-coupons-section {
        .category-quiet-card.category-quiet-card__image-content {
            margin-top: 0;
            margin-bottom: 80px;
            @include mobile {
                margin-bottom: 60px;
            }
            .category-quiet-card__image-wrap {
                @include desktop {
                    padding-left: 0px;
                }
                .category-quiet-card__image-wrap__desktop-image {
                    height: 100%;
                }
            }
        }
    }
}
