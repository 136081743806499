@import '../../../../../styles/main.scss';
.brand-ford {
    .book-service-cta {
        display: flex;
        justify-content: center;
        padding: 20px 0 20px 0;
        position: fixed;
        bottom: 0;
        z-index: 999;
        box-sizing: border-box;
        height: 80px;
        width: 100%;
        border: 1px solid $disabled-on-dark;
        background-color: $fds-color-white;
        box-shadow: 0 -2px 20px 2px rgba(0, 0, 0, 0.16);
    }
}
