@import '../../../styles/print';

@page {
    size: auto;
    margin: 20mm;
}
body {
    margin: 0;
    padding: 0;
}
.shared-view.vehicle-health-report-view {
    @include print {
        background-color: $fds-color-white;

        .vhr-page-disclaimer {
            margin-top: 0px;
        }
    }
    .vhr-preferences-section {
        @include print {
            background-color: $fds-color-white;
            margin-top: 50px;
            padding: 0px 0px 20px 0px;
        }

        h2 {
            @include print {
                margin: 0px;
            }
        }
        .inner-smart-tile-grid {
            @include print {
                max-width: $max-content-width;
                flex-flow: row wrap;
                justify-content: left;
                padding: 0 0 0 1.5rem;
            }
            .smart-tile {
                @include print {
                    margin: 0.8rem;
                    width: 290px;
                }
            }
        }
    }
    .rewards-section {
        @include print {
            page-break-after: always;
            padding: 0px 40px;
            margin-top: 50px;
        }

        h2 {
            @include print {
                margin: 0px;
            }
        }

        .rewards-3col-container {
            @include print {
                flex-direction: row;
            }

            div {
                @include print {
                    width: 30%;
                }
            }
        }
    }
    .category-quiet-card {
        @include print {
            flex-direction: row !important;
            max-width: 100% !important;
            margin: 0 auto 0px !important;
        }
        &__image-content {
            @include print {
                flex-direction: row-reverse !important;
            }
        }
        &__content-wrap {
            @include print {
                padding-right: 88px !important;
                width: 53% !important;
            }
        }
        .category-quiet-card__content-wrap {
            @include print {
                padding: 0px 30px !important;
            }
        }
        &__image-wrap {
            @include print {
                width: 43% !important;
                &__desktop-image {
                    display: block !important;
                    min-height: 400px !important;
                }
                &__mobile-image {
                    display: none !important;
                }
            }
        }
    }
}

.vehicle-health-report-page {
    @include print {
        margin: 0px 0% 1.5% 0;
    }

    .accordion-section {
        page-break-after: always;
        .vhr-accordion-details,
        .accordion-title {
            @include print {
                background-color: $fds-color-white;
            }
        }

        .panel {
            @include print {
                display: block;
                overflow: visible !important;
            }
        }
    }

    .vhr-download {
        @include print {
            display: none !important;
        }
    }

    .vhr-page-header {
        .back-to-ford-account-link {
            @include print {
                display: none;
            }
        }
    }
}
@include print {
    .print-header {
        .print-header-ford-logo {
            margin-left: 20px;
            margin-top: 5px;
            margin-bottom: 50px;
        }
    }

    .offers-coupons-section {
        page-break-after: always;
        margin-top: 60px;
        page-break-inside: avoid;
    }

    .vhr-service-section {
        padding-top: 60px;
        page-break-after: always;
        page-break-inside: avoid;
    }
}
