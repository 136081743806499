.brand-ford {
    .trailer-checklist-detail-page {
        margin: 5% 7%;
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;
        @include mobile {
            margin: 5% 0%;
        }
        .trailer-checklist-heading-section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 53px;
            @include mobile {
                flex-direction: column;
                margin-bottom: 0px;
            }
            .reset-cta-container {
                @include mobile {
                    align-self: flex-end;
                    margin-right: 7px;
                }
                .trailer-checklist-reset-button {
                    color: $fds-color-dk-gray;
                    &:not(.disabled) {
                        color: $breadcrumb-blue;
                    }
                    &.disabled {
                        cursor: default;
                        .secondary-button-text {
                            text-decoration: none;
                        }
                    }
                    @include mobile {
                        padding: 0px;
                    }
                }
            }
        }
        .trailer-checklist-accordion {
            @include desktop {
                height: auto;
            }
            .trailer-checklist-panel-details {
                @include mobile {
                    align-items: center;
                }
                .accordion-panel-image {
                    width: 284px;
                    display: flex;
                    aspect-ratio: 3/2;
                    margin: 20px 50px;
                    @include mobile {
                        margin: 20px auto;
                        aspect-ratio: 1.7;
                    }
                }

                .trailer-checklist-mark-button {
                    @include mobile {
                        margin: 0px auto 30px auto;
                    }
                    &::before {
                        box-shadow: none;
                    }
                    &:not(.disabled) {
                        &:hover {
                            &::before {
                                border: 1px solid $breadcrumb-blue;
                            }
                        }
                    }
                    &.disabled {
                        &::before {
                            background-color: $lightgrey;
                        }
                        &:hover {
                            &::before {
                                background-color: $lightgrey !important;
                            }
                        }
                    }
                    max-width: auto;
                    margin: 0px 80px 30px 80px;
                    border: 1px solid $breadcrumb-blue;
                    border-radius: 20px;
                }
            }
            .trailer-checklist-accordion-header {
                color: $breadcrumb-blue;
                display: flex;
                align-items: center;
                @include mobile {
                    margin-bottom: 10px;
                }
                @include desktop {
                    margin-left: 30px;
                }
                .trailer-checklist-accordion-circle {
                    display: inline-flex;
                    justify-content: center;
                    width: 15px;
                    height: 15px;
                    &.number {
                        border-radius: 50%;
                        border: 1px solid $breadcrumb-blue;
                    }
                    &.tick {
                        content: url('../../../../assets/green-checkmark.svg');
                    }
                    @include mobile {
                        float: left;
                        margin-top: 4px;
                    }

                    .trailer-checklist-accordion-number {
                        font-size: 10px;
                        font-family: $f1-font-regular;
                        @include mobile {
                            margin-right: 2px;
                        }
                    }
                }
                .trailer-checklist-accordion-title {
                    font-size: 16px;
                    font-family: $f1-font-regular;
                    line-height: 24px;
                    margin-left: 10px;
                    @include mobile {
                        float: left;
                        width: 100%;
                        text-align: left;
                    }
                }
            }
            .fds-arrow {
                color: #00095b;
            }
        }

        .trailer-checklist-header {
            font-size: 40px;
            line-height: 48px;
            font-family: $f1-font-medium;
            color: $breadcrumb-blue;
            margin-bottom: 16px;
            @include mobile {
                text-align: center;
                margin-bottom: 18px;
            }
        }

        .trailer-checklist-description {
            font-size: 16px;
            font-family: $f1-font-regular;
            line-height: 26px;
        }

        .light-check-description {
            font-size: 16px;
            font-family: $f1-font-regular;
            line-height: 26px;
            text-align: left;
            color: $fds-color-dk-gray;
            margin-left: 95px;
            @include mobile {
                text-align: center;
                margin: 0px 15px;
            }
        }

        .light-check-sub-description {
            font-size: 10px;
            font-family: $f1-font-regular;
            line-height: 26px;
            text-align: left;
            color: $fds-color-dk-gray;
            margin-left: 95px;
            @include mobile {
                text-align: center;
                margin: 0px 15px;
            }
        }

        .light-check-image {
            display: flex;
            max-width: 284px;
            margin: 20px 95px 20px;
            @include mobile {
                margin: 40px auto 20px auto;
            }
        }

        .light-check-circle {
            height: 12px;
            width: 15px;
        }
    }
}
