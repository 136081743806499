@import '../../../../../styles/main.scss';
.brand-ford {
    .slow-tire-leak-details-wrapper {
        padding: 0 100px 0 100px;
        @include mobile {
            padding: 20px;
        }
        .slow-tire-leak-header {
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            font-size: 34px;
            line-height: 40px;
            margin-top: 18px;
            display: flex;
            justify-content: space-between;

            @include mobile {
                font-size: 28px;
                line-height: 36px;
                text-align: left;
                margin-bottom: 24px;
            }
            .stl-details-download-copy-wrapper {
                cursor: pointer;
                .stl-details-download-copy-icon {
                    width: 30px;
                    height: 25px;
                }
                @include print {
                    display: none !important;
                }
            }
        }
        .slow-tire-leak-content-wrapper {
            display: flex;
            grid-template-columns: 200px 400px 200px;
            column-gap: 30px;
            margin-top: 40px;
            @media screen and (max-width: 1200px) {
                grid-template-columns: 200px 400px;
            }

            @include mobile {
                display: grid;
                grid-template-columns: 100%;
            }
            .progress-bar {
                margin-bottom: 20px;
                margin-right: 30px;
            }
            .slow-tire-leak-data-wrapper {
                .recommened-label {
                    color: $fds-color-disabled-light;
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    line-height: 24px;
                    white-space: nowrap;
                    @include mobile {
                        text-align: center;
                    }
                }
                .recommened-data {
                    display: flex;
                    @include mobile {
                        padding-left: 40px;
                    }
                }
                .front-tyre-wrapper {
                    margin-right: 40px;
                    margin-left: 10px;
                    .front-tyre {
                        color: $fds-color-primary;
                        font-family: $f1-font-regular;
                        font-size: 28px;
                        line-height: 36px;
                        text-align: center;
                    }
                    .front-tyre-label {
                        color: $fds-color-disabled-light;
                        font-family: $f1-font-regular;
                        font-size: 14px;
                        line-height: 24px;
                        text-align: center;
                        white-space: nowrap;
                    }
                }
                .divider-line {
                    height: 72px;
                    width: 1px;
                    opacity: 0.4;
                    transform: scaleX(-1);
                    background-color: $fds-color-black;
                }
                .rear-tyre-wrapper {
                    margin-left: 40px;
                    .rear-tyre {
                        color: $fds-color-primary;
                        font-family: $f1-font-regular;
                        font-size: 28px;
                        line-height: 36px;
                        text-align: center;
                        white-space: nowrap;
                    }
                    .rear-tyre-label {
                        color: $fds-color-disabled-light;
                        font-family: $f1-font-regular;
                        font-size: 14px;
                        line-height: 24px;
                        text-align: center;
                        white-space: nowrap;
                    }
                }
            }
            .slow-tire-leak-book-service {
                margin-top: 20px;
            }
        }
        .slow-tire-leak-do-not-use {
            color: $fds-color-primary;
            margin-top: 20px;
            @include mobile {
                margin-top: 0px;
                font-size: 14px;
                margin-bottom: 20px;
            }
        }
        .slow-tire-leak-status-wrapper {
            color: $fds-text;
            font-family: $f1-font-regular;
            font-size: 14px;
            margin-top: 20px;
            @include desktop {
                width: 60%;
            }
        }
        .alert-color {
            color: $error-message-text !important;
        }
        .warning-color {
            color: $fds-color-orange !important;
        }
        .last-detected {
            margin-top: 20px;
            margin-bottom: 30px;
            width: 65%;
            font-size: 14px;
            @include mobile {
                width: 100%;
            }
            .last-detected-label {
                color: $fds-color-disabled-light;
                font-family: $f1-font-regular;
            }
            .last-detected-date {
                color: $fds-color-primary;
            }
        }
        .accordion-section {
            .accordion-title {
                color: $fds-color-primary;
                font-size: 16px;
                line-height: 24px;
                .fds-chevron-wrap {
                    color: $fds-color-primary;
                }
            }
            .panel.expanded {
                color: $fds-color-primary;
                margin: 16.5px 16px 40.5px 16px;
                text-align: left;
            }
        }
    }
}
